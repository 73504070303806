import React, { Component } from "react";
import Slide from "react-reveal";

class Services extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const serivces = this.props.data.map(function (service) {
      return (
        <Slide left duration={1300}>
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>{service.name}</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{service.description}</div>
            </div>
          </div>
        </div>
      </Slide>
      );
    });

    return (
      <section id="services">
        <h1 style={{textAlign: "center"}}>Services</h1>
        {serivces}
        <h4 style={{textAlign: "center", color: "grey"}}>Schedule a free consultation today.</h4>
      </section>
    );
  }
}

export default Services;
